@import url('https://fonts.googleapis.com/css2?family=Signika+Negative:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&family=Oxygen:wght@300;400;700&display=swap');

.header {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1vw;
        font-weight: 700;
        font-style: normal;
    }

    .font-btn {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1vw;
        font-weight: 700;
        font-style: normal;

        @media (max-width: 1024px) {
            font-size: 2vw;
        }
    }

    .phone {
        .font-title {
            font-family: "Oxygen", sans-serif;
            font-size: 1.2vw;
            line-height: 1.2vw;
            font-weight: 100;

            @media (max-width: 768px) {
                font-size: 3vw;
                line-height: 4vw;
            }
        }
        .font-content {
            font-family: "Oswald", sans-serif;
            font-size: 1.8vw;
            line-height: 1.8vw;
            font-weight: 500;
            @media (max-width: 768px) {
                font-size: 4vw;
                line-height: 5vw;
            }
        }  
    }
}

.footer {
    .font-slogan {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 1024px) {
            font-size: 3vw;
            line-height: 3vw;
        }
        @media (max-width: 768px) {
            font-size: 7vw;
            line-height: 7vw;
        }
    }
    .font-title-mail {
        font-family: "Signika Negative", sans-serif;
        font-size: 2vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 4vw;
        }
    }
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 1.2vw;
        font-weight: 700;
        color: #fff;

        @media (max-width: 768px) {
            font-size: 6vw;
            line-height: 6vw;
        }
      
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.9vw;
        line-height: 1.5vw;
        font-weight: 400;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 7vw;
        }
    }
    .font-content1 {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.5vw;
        font-weight: 400;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 7vw;
        }
    }

    .font-coppyRight {
        font-family: monospace;
        font-size: 0.8vw;
        line-height: 1.2vw;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 3.8vw;
            line-height: 4vw;
        }
    }
    
}

.home {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 2vw;
        line-height: 2.5vw;
        font-weight: 700;
        text-align: center;
        color: #fff;
        @media (max-width: 768px) {
            font-size: 6vw;
            line-height: 6vw;
        }
    }

}

.menu {
    .text-menu-header {
        font-family: "Signika Negative", sans-serif;
        font-size: 4.5vw;
        line-height: 6vw;
        font-weight: 500;
    }
}

.trust {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 1.2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 4.5vw;
            font-weight: 600;
        }
    }

    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.9vw;
        line-height: 1.2vw;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6vw;
        }
    }
}

.booking {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 1.2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 6vw;
            line-height: 8vw;
        }
    }

    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.9vw;
        line-height: 1.2vw;
        font-weight: 400;

        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6vw;
        }
    }
}

.banner {
    .font-slogan {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 1024px) {
            font-size: 3vw;
            line-height: 3vw;
        }
        @media (max-width: 768px) {
            font-size: 5vw;
            line-height: 5vw;
        }
    }
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size:4vw;
        line-height: 5vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 8vw;
            line-height: 10vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 1.2vw;
        font-weight: 400;
        text-align: center;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6vw;
        }
    }   
}

.search {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 8vw;
            line-height: 10vw;
        }
    }

    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 2.5vw;
        line-height: 3vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 8vw;
            line-height: 10vw;
        }
    }

    .font-search {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1vw;
        font-weight: 700;

        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 5.5vw;
        }
    }

    
}

.highlights {
    .font-slogan {
        font-family: "Signika Negative", sans-serif;
        font-size: 2vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 8vw;
            line-height: 12vw;
        }
    }
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 2.5vw;
        line-height: 3vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 6vw;
            line-height: 8vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.6vw;
        font-weight: 100;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 7.5vw;
        }
    }   
    .font-titlelist {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.1vw;
        line-height: 1.8vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 5vw;
            line-height: 7vw;
        }
    }  
    .font-contentList {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.9vw;
        line-height: 1.5vw;
        font-weight: 100;

        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6.5vw;
        }
    }   
}

.category {
    .font-topic {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 4vw;
        }
    }
    .font-topic2 {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.8vw;
        line-height: 1.2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 4vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.6vw;
        font-weight: 100;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6vw;
        }
    }   
}

.about {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 3vw;
        font-weight: 700;

        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 5.5vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.8vw;
        line-height: 1.6vw;
        font-weight: 100;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 3vw;
            line-height: 5vw;
        }
    }   
}

.introduce {
    .font-slogan {
        font-family: "Signika Negative", sans-serif;
        font-size: 2vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 7vw;
            line-height: 12vw;
        }
    }
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 2.5vw;
        line-height: 3vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 6vw;
            line-height: 9vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.6vw;
        font-weight: 100;
        @media (max-width: 768px) {
            font-size: 4.5vw;
            line-height: 7.5vw;
        }
    }   
    .font-titlelist {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.1vw;
        line-height: 1.8vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 5vw;
            line-height: 7vw;
        }
    }  
    .font-contentList {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.9vw;
        line-height: 1.5vw;
        font-weight: 100;

        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6.5vw;
        }
    }   
    .font-contentControduce {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.8vw;
        line-height: 1.5vw;
        font-weight: 600;

         @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 6.5vw;
        }
    }   
}

.asideCategory {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.5vw;
        line-height: 3vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 9vw;
            line-height: 10vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.6vw;
        font-weight: 400;
       
    }   
}

.itemsCard {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 1.2vw;
        line-height: 2vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 6vw;
            line-height: 6.5vw;
        }
       
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 1vw;
        line-height: 1.6vw;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 5vw;
        }
    }   
    .font-text {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.8vw;
        line-height: 1.6vw;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 4vw;
            line-height: 5vw;
        }
    }   
}

.font-category {
    font-family: "Signika Negative", sans-serif;
    font-size: 1vw;
    line-height: 1.6vw;
    font-weight: 400;
    @media (max-width: 768px) {
        font-size: 5.5vw;
        line-height: 7vw;
        font-weight: 400;
    }
}

.infoContact {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.9vw;
        line-height: 1.5vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 5vw;
            line-height: 7vw;
        }
    }
    .font-content {
        font-family: "Signika Negative", sans-serif;
        font-size: 0.8vw;
        line-height: 1.6vw;
        font-weight: 400;
        @media (max-width: 768px) {
            font-size: 5vw;
            line-height: 7vw;
        }
    }   
}

.form {
    .font-title {
        font-family: "Signika Negative", sans-serif;
        font-size: 2vw;
        line-height: 4vw;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 7vw;
            line-height: 10vw;
        }
    }
}