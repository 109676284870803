.all-center {
    display: flex ;
    justify-content: center;
    align-items: center;
}

.all-start {
    display: flex ;
    justify-content: start;
    align-items: start;
}

.scale-icon {
    transition: transform 1s ease;
    cursor: pointer;
    &:hover {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

.hover-items {
    transition: transform 0.5s ease;
    cursor: pointer;
    &:active {
        box-shadow: none;
        transform: translateY(5px);
    }
    &:hover {
        box-shadow: none;
        transform: translateY(5px);
    }
}


.menu {
    z-index: 100000;
    position: fixed;
    top: 0;
    left: 0;
    width: 300px;
    height: 100vh;
    background-color: white;
    box-shadow: -12px 0 15px -3px rgba(0, 0, 0, 0.2);
}

.menu-hidden {
    transition: transform 0.5s ease-out;
    transform: translateX(-120%);
}

.menu-visible {
    transition: transform 0.5s ease-out;
    transform: translateX(0);
}

@keyframes socialFill {
    0% {-webkit-transform: rotate(0) scale(1) skew(1deg);  }
    10% {-webkit-transform: rotate(-25deg) scale(1) skew(1deg);}
    20% {-webkit-transform: rotate(25deg) scale(1) skew(1deg);}
    30% {-webkit-transform: rotate(-25deg) scale(1) skew(1deg);}
    40% {-webkit-transform: rotate(25deg) scale(1) skew(1deg);}
    50% {-webkit-transform: rotate(0) scale(1) skew(1deg);}
    100% {-webkit-transform: rotate(0) scale(1) skew(1deg);}
}


.fillColorZalo {
    box-shadow: 0 0 0 0 #2196F3;
    background-color: rgba(33, 150, 243, 0.7);
    position: absolute;
    width: 3.5vw;
    height: 3.5vw;
    border-radius: 100%;
    @media (max-width: 768px) {
        width: 17vw;
        height: 17vw;
    }

}

.fillColorPhone {
    box-shadow: 0 0 0 0 #6fd626;
    background-color: rgba(75, 243, 33, 0.7);
    position: absolute;
    width: 3.5vw;
    height: 3.5vw;
    border-radius: 100%;
    @media (max-width: 768px) {
        width: 17vw;
        height: 17vw;
    }
}

.fillColorFacebook {
  box-shadow: 0 0 0 0 #2196F3;
  background-color: rgba(33, 150, 243, 0.7);
  position: absolute;
  width: 3.5vw;
  height: 3.5vw;
  border-radius: 100%;
  @media (max-width: 768px) {
      width: 17vw;
      height: 17vw;
  }

}

.shadow-custom1 {
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.shadow-custom2 {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.rings {
    animation: socialFill 1.5s infinite ease-in-out;
}

.zoom {
    animation: zoom 1.3s infinite;
    transition: all .5s;
}

@keyframes zoom{
    0%{transform:scale(.9)}
    70%{transform:scale(1);box-shadow:0 0 0 15px transparent}
    100%{transform:scale(.9);box-shadow:0 0 0 0 transparent}
}


input[type="checkbox"] {
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    background-color: #c0c5c9;
    width: 3.5vw;
    height: 2vw;
    border-radius: 20px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 0.7s ease;
    cursor: pointer;

    @media (max-width: 768px) {
        width: 15vw;
        height: 8vw;
    }
}

input[type="checkbox"]::before {
    content: "";
    position: absolute;
    left: 0;
    width: 2vw;
    height: 2vw;
    background-color: #fff;
    border-radius: 10vw;
    transform: scale(0.8);
    transition: all 0.7s ease;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-image: url('../assets/logo/vietnnamese.png');
    background-size: cover;
    background-position: center;
    @media (max-width: 768px) {
        width: 8vw;
        height: 8vw;
    }
}

input[type="checkbox"]:checked::before {
    background-image: url('../assets/logo/united-kingdom.png');
    left: 1.5vw;
    @media (max-width: 768px) {
        left: 7vw;
    }
}

.title {
    &::before {
        position: absolute;
        content: '';
        background: #62fff0;
        height: 0.3vw;
        width: 6vw;
        right: 0;
        bottom: 0;
        z-index: -1;

        @media (max-width: 768px) {
        width: 20vw;
        height: 0.9vw;
        bottom: -1vw;
        }
    }
}

.bg-contact {
    background: url(../assets/img/bg_footer_contact.png) no-repeat;
    background-size: initial;
    background-position: left center;
}

.hover-line {
    position: relative;
    display: inline-block;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 3px;
      bottom: 0;
      left: 0;
      background-color: #62fff0;
      transition: width 0.5s ease-in-out;
    }

    &:hover::after {
      width: 100%;
      animation: borderExpand 0.5s forwards;
    }
}


@keyframes borderExpand {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
}

.banner-nhatrang {
    background-image: url(../assets/logo/banner-nhatrang.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner-content {
  background-image: url(../assets/img/banner-content-f.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.banner-danang {
    background-image: url(../assets/logo/banner-danang.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-banner {
    background-image: url(../assets/img/banner-moto-1.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 300px 0px 300px 0px;
    clip-path: polygon(88% 100%, 100% 80%, 100% 0, 100% 0, 0 0, 0 90%);

    @media (max-width: 768px) {
        padding: 400px 0px 400px 0px;
        clip-path: polygon(90% 100%, 121% 80%, 100% 0, 100% 0, 0 0, 0 94%);
    }
}

.bg-danang {
    background-image: url(../assets/logo/banner-danang.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 300px 0px 300px 0px;
    clip-path: polygon(88% 100%, 100% 80%, 100% 0, 100% 0, 0 0, 0 90%);

    @media (max-width: 768px) {
        padding: 400px 0px 400px 0px;
        clip-path: polygon(90% 100%, 121% 80%, 100% 0, 100% 0, 0 0, 0 94%);
    }
}

.bg-nhatrang {
    background-image: url(../assets/logo/banner-nhatrang.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 300px 0px 300px 0px;
    clip-path: polygon(88% 100%, 100% 80%, 100% 0, 100% 0, 0 0, 0 90%);

    @media (max-width: 768px) {
        padding: 400px 0px 400px 0px;
        clip-path: polygon(90% 100%, 121% 80%, 100% 0, 100% 0, 0 0, 0 94%);
    }
}

.bg-option {
    background-image: url(../assets/img/banner-motoaa.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: repeat;
    clip-path: polygon(20% 100%, 100% 80%, 100% 0, 100% 0, 0 0, 0 90%);
}

.bg-highlights {
    background-image: url(../assets/img/banner-motoaa.jpg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-repeat: repeat;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 110px 0px 160px 0px;
    clip-path: polygon(88% 100%, 100% 80%, 100% 0, 100% 0, 0 0, 0 90%);

    @media (max-width: 768px) {
        padding: 60px 0px 120px 0px;
        clip-path: polygon(88% 100%, 100% 92%, 100% 0, 100% 0, 0 0, 0 96%);
    }
}

.bg-category {
    background: #EEEEEE;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 140px 0px 120px 0px;
    clip-path: polygon(16% 0%, 100% 8%, 100% 100%, 0 100%, 0 9%);
}

.bg-footer {
    background-image: url(../assets/img/banner-footer.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    clip-path: polygon(10% 0%, 100% 24%, 100% 100%, 0 100%, 0 16%);

    @media (max-width: 768px) {
       clip-path:  polygon(10% 0%, 100% 10%, 100% 100%, 0 100%, 0 9%);
    }
}

.bg-overlay {
    background-color: #000000;
    opacity: 0.7;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.bg-contact1 {
  background-image: url(../assets/img/banner-contact.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  padding: 300px 0px 300px 0px;
  clip-path: polygon(88% 100%, 100% 80%, 100% 0, 100% 0, 0 0, 0 90%);

  @media (max-width: 768px) {
      padding: 200px 0px 200px 0px;
      clip-path: polygon(90% 100%, 121% 80%, 100% 0, 100% 0, 0 0, 0 94%);
      background-position: left center;
  }
}

.bg-overLay {
    position: absolute;
    color: #fff;
    padding: 8px 16px;
    overflow: hidden;
    inset: 0px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    height: 100%;
    width: 94.5%;
    margin: 0 0.5vw;
    padding: 0 0.5vw;
    @media (max-width: 768px) {
      width: 100%;
  }

    &:hover {
         background: transparent; 
    }
}

.btn-box {
    background: #62fff0;
    color: #000;
    transition: .3s ease;
    &:hover {
        background: #000;
        color: #fff;
        transition: .3s ease;
    }

    &:hover {
        .design-box {
            transition: .3s ease;
            background-color: #62fff0;
            &::after  {
                content: '';
                border-color: transparent transparent transparent #000;
                transform: rotate(180deg);
                transition: .6s ease;
            }
        }
    }
}


.design-box {
    background-color: #222222;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    width: 1vw;
    height: 1vw;
    position: absolute;
    right: -0.7vw;
    top: 50%;
    transform: translateY(-50%);
    transition: .3s ease;

    @media (max-width: 768px) {
        width: 5vw;
        height: 5vw;
        right: -4vw;
    }

    &::after {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 4.5px 0 4.5px 6px;
        border-color: transparent transparent transparent #fff;
        transition: .6s ease;
    }
}


.items-category{
    .items-products{
      transition: all .3s;
      .items-content{
        overflow: hidden;
        img{
            transition: .6s ease;
          }
          &:hover{
            img {
              transform: scale(1.07);
              transition: .6s ease;
            }
          }
      }
      .fl-woo-item-bottom-content{
        .fl--woo-add-to-cart-wrap {
          .fl--order{
            display: flex;
            position: absolute;
            right: 0;
            bottom: -20px;
            left: 0;
            margin-top: 40px;
            font-size: .9rem;
            color: #fff;
            text-align: center;
            transform: translateY(100%);
            z-index: -1;
            perspective: 600px;
            transform-style: preserve-3d;
 
            .order-now{
              line-height: 1;
              font-size: 12px;
              text-transform: uppercase;
              padding: 17px 30px;
              border-radius: 0;
              color: #fff;
              transition: .3s ease;
              width: 100%;
              display: table;
              transform: rotateX(-100deg);
              transform-origin: 0 0;
              backface-visibility: hidden;
              &:hover{
                transition: .3s ease;
              }
              &.added{
                display: none;
              }
            }
          }
        }
      }
    }
    &:hover{
      z-index: 2;
      position: relative;
      .items-products{
        box-shadow: 0 5px 30px 5px rgba(0, 0, 0, 0.12);
        transform: translateY(-30px);
        transition: .3s ease;
        .fl--woo-add-to-cart-wrap .fl--order{
            .order-now{
            transform: rotateX(0);
          }
        }
      }
 
    }
 
  }
 
 
.fade-enter {
    opacity: 0;
    transform: translateY(10px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(10px);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.item-enter {
    opacity: 0;
    transform: scale(0.9);
}

.item-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.item-exit {
    opacity: 1;
    transform: scale(1);
}

.item-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.bg-overlayBanner {
    background-color: #0000009f;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.animateShine {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    &:hover .bg-overlayBanner {
        opacity: 0;
    }

    &:hover::before {
        animation: shine 2s;
        
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -85%;
        z-index: 2;
        display: block;
        width: 50%;
        height: 100%;
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
        -webkit-transform: skewX(-25deg);
        transform: skewX(-25deg);
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}
.checkbox-wrapper-4  {
  .cbx {
    transition: all 0.2s ease;
    &:not(:last-child) {
      margin-right: 6px;
    }

    span {
      float: left;
      vertical-align: middle;
      transform: translate3d(0, 0, 0);
      

      &:first-child {
        position: relative;
        width: 1vw;
        height: 1vw;
        border-radius: 4px;
        transform: scale(1);
        border: 1px solid #cccfdb;
        transition: all 0.2s ease;
        box-shadow: 0 1px 1px rgba(0, 16, 75, 0.05);
        
        @media (max-width: 768px) {
          width: 6vw;
        height: 6vw;
      }

        svg {
          position: absolute;
         
          fill: none;
          stroke: #fff;
          stroke-width: 2;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-dasharray: 16px;
          stroke-dashoffset: 16px;
          transition: all 0.3s ease;
          transition-delay: 0.1s;
          transform: translate3d(0, 0, 0);
      }
      }
    }
      
  }
}

.checkbox-wrapper-4 * {
    box-sizing: border-box;
  }
  .checkbox-wrapper-4 .cbx:hover span:first-child {
    border-color: #07f;
  }
  .checkbox-wrapper-4 .inp-cbx {
    position: absolute;
    visibility: hidden;
  }
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child {
    background: #07f;
    border-color: #07f;
    animation: wave-4 0.4s ease;
  }
  .checkbox-wrapper-4 .inp-cbx:checked + .cbx span:first-child svg {
    stroke-dashoffset: 0;
  }
  .checkbox-wrapper-4 .inline-svg {
    position: absolute;
    width: 0;
    height: 0;
    pointer-events: none;
    user-select: none;
  }
  @media screen and (max-width: 640px) {
    .checkbox-wrapper-4 .cbx {
      width: 100%;
      display: inline-block;
    }
  }
  @-moz-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-webkit-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @-o-keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  @keyframes wave-4 {
    50% {
      transform: scale(0.9);
    }
  }
  
.itemsCard {
      transition: all 0.4s ease-in-out;
  &:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
}

.boxContact {
  padding: 0.9375rem 1.25rem;
  width: 100%;
  font-family: inherit;
  font-size: 0.9375rem;
  color: #777777;
  border: 0.0625rem solid;
  border-radius: 0.125rem;
  margin-bottom: 1.5625rem;
  transition: .3s ease;
  border-color: transparent !important;
  &:hover, :active {
    box-shadow: 0 0.5rem 1.375rem 0 rgba(0, 0, 0, 0.1) !important;
    transition: .3s ease;
    background: #fff !important;
    border-color: transparent !important;
  }
}

.textareaContact {
  padding: 0.9375rem 1.25rem;
    width: 100%;
    font-family: inherit;
    font-size: 0.9375rem;
    color: #777777;
    border: 0.0625rem solid;
    border-radius: 0.125rem;
    border-color: transparent !important;
    margin-bottom: 1.5625rem;
    transition: .3s ease;
    &:hover, :active {
      box-shadow: 0 0.5rem 1.375rem 0 rgba(0, 0, 0, 0.1) !important;
      transition: .3s ease;
      background: #fff !important;
      border-color: transparent !important;
    }
}